<!-- 版本管理 -->
<template>
    <div class="outContainer">
      <!-- 筛选条件栏 -->
      <div class="search">
        <el-input class="each" v-model="searchInfo.param.name" placeholder="车主姓名"></el-input>
        <el-input class="each" v-model="searchInfo.param.phone" placeholder="联系电话"></el-input>
        <el-select class='each' v-model="searchInfo.param.merchantId" placeholder="商户筛选" clearable v-if="userInfo.roleLevel < 5">
          <el-option
            v-for="item in merchantOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id">
          </el-option>
        </el-select>
        <el-button size="mini" type="primary" icon="el-icon-search" @click="hanldleSearch('search')">搜索</el-button>
        <el-button size="mini" type="primary" icon="el-icon-close" @click="hanldleSearch('clear')">清空搜索条件</el-button>
      </div>
      <!-- 表格 -->
      <div class="tableContainer">
        <el-table :data="tableData" style="width: 100%" height="100%">
          <el-table-column align="center" label="昵称" prop="wechatName"></el-table-column>
          <el-table-column align="center" label="姓名" prop="name"></el-table-column>
          <el-table-column align="center" label="联系电话" prop="phone"></el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
    </div>
  </template>
  
  <script>
  import pagination from '@/components/Pagination.vue';
  import { getEBikeUserList,getMerchantList } from '@/api/public.js';
  import { timeChange } from '@/api/publicFun.js';
  export default {
    data() {
      return {
        searchInfo:{ // 筛选条件
          pageNo:1,
          pageSize:10,
          total:1,
          param:{
            name:null,
            phone:null,
            merchantId:null,
          },
        },
        tableData: [], // 表格数据
        merchantOptions:[], // 商户选项
        userInfo:JSON.parse(localStorage.getItem('userInfo'))
      }
    },
  
    components: {
      pagination
    },
  
    computed: {},
  
    mounted() {
      this.init();
      this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : "";
    },
  
    methods: {
      // 筛选事件
      hanldleSearch(type){
        this.searchInfo.pageNo = 1;
        if(type == 'clear'){
          this.searchInfo.param = {};
        }else{
          this.searchInfo.param.name = this.searchInfo.param.name == "" ? null : this.searchInfo.param.name;
          this.searchInfo.param.phone = this.searchInfo.param.phone == "" ? null : this.searchInfo.param.phone;
        }
        this.init();
      },
      // 初始化获取表格数据
      async init(){
        await getEBikeUserList(this.searchInfo).then(res=>{
          if(res.success){
            this.tableData = res.data.list;
            this.searchInfo.total = res.data.total;
            this.tableData.forEach(element => {
              let eachTime = timeChange(element.createTime);
              element.createTime = `${eachTime.year}-${eachTime.month}-${eachTime.day}  ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
              for(let key in element){
                if((!element[key] && element[key]!=0) || element[key] == ""){
                  element[key]='--';
                }
              }
            });
          }else{
            this.tableData = [];
            this.searchInfo.total = 0;
          }
        })
      },
      // 获取商户列表
      async getMerchantOptions(){
        let data = {
          pageNo:1,
          pageSize:1000,
          param:{
            name:'',
          }
        }
        await getMerchantList(data).then(res=>{
          if(res.success){
            this.merchantOptions = res.data.list;
          }else{
            this.merchantOptions = [];
          }
        })
      },
      // 分页插件事件
      callFather(parm) {
        this.searchInfo.pageNo = parm.currentPage;
        this.init();
      },
    },
  };
  </script>
  <style scoped></style>
  